import $ from "expose-loader?exposes=$,jQuery!jquery";
import '../css2/swiper.styl';
import './css/main.css';
import '../css2/site.styl';
import '../js/library.js';
import '../js/main.js';

import { defineCustomElements } from 'vidstack/elements';
setTimeout(() => {defineCustomElements();}, 500);


var Isotope = require('isotope-layout');
import 'isotope-packery';

var SmoothScroll = require('smooth-scroll');

import imagesLoaded from 'imagesloaded';

// let vidPlayers = document.querySelectorAll('media-player');
// if (vidPlayers.length > 0) {
//     let vd;
//     for (vd=0; vd < vidPlayers.length; vd++) {
//         vidPlayers[vd].addEventListener('provider-change', (event) => {
//             const provider = event.detail;
//             if (provider?.type === 'hls') {
//                 console.log('HLS config change');
//                 provider.config = { startLevel: 7 };
//             };
//         });
//     };
// };

var isogrid = document.getElementsByClassName("iso-grid");
if ( isogrid.length > 0 ) {
var iso = new Isotope( '.iso-grid', {
    itemSelector: '.grid-item',
    stamp: '.stamp',
    hiddenStyle: {
      opacity: 0
    },
    // layoutMode: 'packery',
    percentPosition: true,
    // masonry: {
    //     columnWidth: '.grid-sizer',
    // },
    visibleStyle: {
      opacity: 1
    },
  });

  function showItems () {
    var grid = document.getElementsByClassName("iso-grid");
    var i;
    for (i = 0; i < grid.length; i++) {
        grid[i].style.opacity = 1;
    };
    };

    showItems();


    imageHeightFix();
};


var isogridnews = document.getElementsByClassName("iso-grid-news");
if ( isogridnews.length > 0 ) {
var isonews = new Isotope( '.iso-grid-news', {
    itemSelector: '.grid-item',
    stamp: '.stamp',
    hiddenStyle: {
      opacity: 0
    },
    // layoutMode: 'packery',
    percentPosition: true,
    // masonry: {
    //     columnWidth: '.grid-sizer',
    // },
    visibleStyle: {
      opacity: 1
    },
  });

  function showNewsItems () {
    setTimeout(function() {var grid = document.getElementsByClassName("iso-grid-news");
    var i;
    for (i = 0; i < grid.length; i++) {
        grid[i].style.opacity = 1;
    };}, 300)
    
    };

    var loadelem = document.getElementById("iso-grid-news");

    imagesLoaded( loadelem, function() { isonews.layout();showNewsItems(); } );
};




function imageHeightFix() {

    if (CSS.supports("aspect-ratio: 1 / 1")) {
  
  
        // Should only need to add this to the 'else' part of the if statement. This is a temporary fix for a safari bug.
  
        var images = document.getElementsByClassName("lazy-img");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
  
  
  
            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };
  
            // console.log(newheight);
  
        };
  
        // document.addEventListener('lazyloaded', function (e) {
        //     if (e.target.parentElement.classList.contains("imagebackground")) {
        //         e.target.parentElement.style.height = 'auto';
        //     } else {
        //         e.target.style.height = 'auto';
        //     };
        // });
  
    } else {
        var images = document.getElementsByClassName("lazy-img");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
  
  
  
            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };
  
            // console.log(newheight);
  
        };
  
        // document.addEventListener('lazyloaded', function (e) {
        //     if (e.target.parentElement.classList.contains("imagebackground")) {
        //         e.target.parentElement.style.height = 'auto';
        //     } else {
        //         e.target.style.height = 'auto';
        //     };
        // });
    };
  };



//   ---------------------------- Mobile Project Scroll ----------------------------

function scrollToProject() {
    var scroll = document.getElementsByClassName("project-scroll");

    if (scroll.length > 0 && window.innerWidth < 800 ) {

        var mainProject = document.getElementById("grid").getAttribute("data-main-project");

        var scrolltothis = "#" + mainProject;

        var scrolldiv = document.getElementById(mainProject);

        console.log("Start scrolling to: " + scrolltothis);

        var scroll = new SmoothScroll();
        scroll.animateScroll(scrolldiv);

    } else false;
}

window.onload = function () {
    scrollToProject();
}